import { CombinedError } from 'urql';

import ErrorReporter from '@lyearn/core/utils/ErrorReporter';
import { request } from '@/helper/request';

import { AllConfigsDocument, AllConfigsQuery } from './graphql/query/generated/AllConfigs';
import { ConfigError } from './error';

const requestConfigs = ({
  body,
  xLearner,
}: {
  body: string;
  xLearner?: boolean;
}): Promise<{
  data?: AllConfigsQuery;
  error?: CombinedError;
  extensions?: Record<string, any>;
}> =>
  request(`/api/graphql?op=Config`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      accept: 'application/graphql+json, application/json',
      ...(xLearner === true ? { 'x-learner': 'true' } : {}),
    },
    body,
  }).then((res) => res.json());

export const fetchConfig = (cofigTypes: string[], xLearner?: boolean) => {
  const body = {
    query: AllConfigsDocument.loc?.source.body,
    variables: {
      filters: {
        keys: cofigTypes,
      },
    },
  };

  return requestConfigs({ body: JSON.stringify(body), xLearner }).then((res) => {
    if (res?.data?.allConfigs.configs.length) {
      return res.data.allConfigs.configs;
    } else {
      const err = new ConfigError('Failed to fetch config');
      ErrorReporter.error(err, {
        contexts: {
          error: {
            data: res?.error,
          },
          extensions: {
            data: res?.extensions,
          },
        },
      });
      throw err;
    }
  });
};
