import { KnowledgeAssetSubTypeEnum } from '@/types/schema';

import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

const KNOWLEDGE_ASSET_PARENT_ROUTE = '/knowledge-asset';
const LIBRAY_PORTAL_PARENT_ROUTE = '/library-portal';
const ADMIN_KNOWLEDGE_ASSET_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${KNOWLEDGE_ASSET_PARENT_ROUTE}`;

const knowledgeAssetAdminRoutes = {
  KnowledgeAssetTemplates: (type: KnowledgeAssetSubTypeEnum) =>
    `${ADMIN_KNOWLEDGE_ASSET_PARENT_ROUTE}/${type}/templates`,
  KnowledgeAssetCreate: (type: string) => `${ADMIN_KNOWLEDGE_ASSET_PARENT_ROUTE}/${type}/create`,
  KnowledgeAssetDetails: (id: string, type: string = KnowledgeAssetSubTypeEnum.Solution) =>
    `${ADMIN_KNOWLEDGE_ASSET_PARENT_ROUTE}/${type}/${id}/details`,
  KnowledgeAssetReports: (id: string) => `${ADMIN_KNOWLEDGE_ASSET_PARENT_ROUTE}/${id}/reports`,
  KnowledgeAssetEditor: (id: string, type: string = KnowledgeAssetSubTypeEnum.Solution) =>
    `${ADMIN_KNOWLEDGE_ASSET_PARENT_ROUTE}/${type}/${id}/edit`,
  KnowledgeAssetManager: (type: string) => `${ADMIN_KNOWLEDGE_ASSET_PARENT_ROUTE}/${type}`,
  KnowledgeAssetManagerActive: (type: string) =>
    `${ADMIN_KNOWLEDGE_ASSET_PARENT_ROUTE}/${type}/active`,
  KnowledgeAssetManagerPending: (type: string) =>
    `${ADMIN_KNOWLEDGE_ASSET_PARENT_ROUTE}/${type}/pending`,
};

export const knowledgeAssetLearnerRoutes = {
  LibraryPortal: () => LIBRAY_PORTAL_PARENT_ROUTE,
  LibraryPortalSubType: (subType: string) => `${LIBRAY_PORTAL_PARENT_ROUTE}/${subType}`,
  LibraryPortalSearch: () => `${LIBRAY_PORTAL_PARENT_ROUTE}/search`,
  LibraryPortalManagerWithStatus: (type: string, status: string) =>
    `${LIBRAY_PORTAL_PARENT_ROUTE}/${type}/${status}`,
  KnowledgeAssetLearnerDetails: (id: string, type: string = KnowledgeAssetSubTypeEnum.Solution) =>
    `${KNOWLEDGE_ASSET_PARENT_ROUTE}/${type}/${id}/details`,
  KnowledgeAssetLearnerManager: (type: string) => `${KNOWLEDGE_ASSET_PARENT_ROUTE}/${type}`,
  KnowledgeAssetLearnerManagerWithStatus: (type: string, status: string) =>
    `${KNOWLEDGE_ASSET_PARENT_ROUTE}/${type}/${status}`,
  KnowledgeAssetCreateLearner: (type: string) => `${KNOWLEDGE_ASSET_PARENT_ROUTE}/${type}/create`,
  KnowledgeAssetEditLearner: (id: string, type: string = KnowledgeAssetSubTypeEnum.Solution) =>
    `${KNOWLEDGE_ASSET_PARENT_ROUTE}/${type}/${id}/edit`,
};

export default knowledgeAssetAdminRoutes;
