import React from 'react';

import useScreen from '@lyearn/core/hooks/useScreen';
import { cn } from '@lyearn/style';
import { Box } from '@lyearn/ui';

import DefaultLeftNode from '../components/DefaultLeftNode';
import DefaultRightNodeLazy from '../components/DefaultRightNode/index.lazy';
import type { DefaultHeaderProps } from '../types';

function renderCenterNode({
  CenterNode,
  className,
}: Pick<DefaultHeaderProps, 'CenterNode' | 'className'>) {
  if (CenterNode) return <Box className={className}>{CenterNode}</Box>;

  return null;
}

const DefaultHeader: React.FC<DefaultHeaderProps> = React.memo((props) => {
  const { RightNode, LeftNode, CenterNode, className, showShadow, isDisabled } = props;
  const { isLandScapeMode } = useScreen();
  const HeaderLeftNode = LeftNode ? LeftNode : <DefaultLeftNode textColor="text-text-primary" />;
  const HeaderRightNode = RightNode !== undefined ? RightNode : <DefaultRightNodeLazy />;
  const height = isLandScapeMode ? 'h-[52px]' : 'h-[56px]';
  return (
    <Box
      className={cn(
        'insert-padding-top-screen sticky top-0 z-50 w-full max-w-full overflow-x-auto bg-bg-primary',
        showShadow ? 'shadow-01' : undefined,
        isDisabled ? 'pointer-events-none' : undefined,
        className,
      )}>
      <Box
        className={cn('flex w-full flex-row items-center justify-between bg-bg-primary', height)}>
        {LeftNode === null ? null : (
          <Box className="flex flex-shrink-0 flex-row justify-start">{HeaderLeftNode}</Box>
        )}
        {renderCenterNode({
          CenterNode,
          className: LeftNode === null ? 'ps-24' : undefined,
        })}
        <Box className="flex flex-shrink-0 flex-row justify-end">{HeaderRightNode}</Box>
      </Box>
    </Box>
  );
});

export default DefaultHeader;
