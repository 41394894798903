import React from 'react';

import { createContext } from '@lyearn/core/utils/context';
import { ConfigFragment } from '@/components/ConfigsProvider/graphql/fragment/generated/Config';

import { CacheEntry, ConfigContextType, CONFIGS_TYPE } from './types';
import { fetchConfig } from './utils';

// A cache to store config key and its promise that was used to fetch it
// { navConfig: Promise }
export const configsCache = new Map<string, CacheEntry>();

export const [ConfigContextProvider, useConfigsContext] =
  createContext<ConfigContextType>('ConfigContext');

const ConfigsProvider = ({ children }: { children: React.ReactNode }) => {
  const [configs, setConfigs] = React.useState<ConfigContextType['configs']>({});
  const [isFetchingConfigs, setIsFetchingConfigs] = React.useState<
    ConfigContextType['isFetchingConfigs']
  >({});

  return (
    <ConfigContextProvider
      configs={configs}
      isFetchingConfigs={isFetchingConfigs}
      setConfigs={setConfigs}
      setIsFetchingConfigs={setIsFetchingConfigs}>
      {children}
    </ConfigContextProvider>
  );
};

const ADMIN_BEHAVIOUR_ROUTES = ['/admin', '/public/user-survey', '/reports'];

const isAdminBehaviourRoute = (pathname: string) =>
  ADMIN_BEHAVIOUR_ROUTES.some((prefix) => pathname.startsWith(prefix));

export const useFetchConfig = (configType: CONFIGS_TYPE[]) => {
  const context = useConfigsContext();
  if (context === null) {
    throw new Error('useFetchConfig must be used within a ConfigsProvider');
  }

  const { configs, setConfigs } = context;
  // null can be the resolved value of a config key, so we need to specifically check for undefined
  const allConfigsArePresent = configType.every((type) => configs[type] !== undefined);
  const missingConfigs = configType.filter((type) => !configs[type]);
  const promiseExistsForAllConfigs = configType.every((type) => configsCache.has(type));
  const adminRoute =
    typeof location !== 'undefined' ? isAdminBehaviourRoute(location.pathname) : false;
  const xLearner = !adminRoute || undefined;

  if (allConfigsArePresent) {
    return configs;
  } else if (missingConfigs.length) {
    if (!promiseExistsForAllConfigs) {
      const promise = new Promise((resolve) => {
        fetchConfig(missingConfigs, xLearner).then((edges: ConfigFragment[]) => {
          const fetchedConfigs = edges.filter((edge) =>
            missingConfigs.includes(edge.key as CONFIGS_TYPE),
          );
          setConfigs((prevConfigs) => {
            const newConfigs = fetchedConfigs.reduce(
              (acc, config) => ({
                ...acc,
                [config.key]: config[config.key as CONFIGS_TYPE],
              }),
              {},
            );
            return { ...prevConfigs, ...newConfigs };
          });
          resolve(fetchedConfigs);
        });
      });

      missingConfigs.forEach((type) => {
        configsCache.set(type, promise);
      });
    }
    const allPromises = configType.map((type) => configsCache.get(type));
    throw Promise.all(allPromises);
  } else {
    throw new Error('Invalid config type');
  }
};

export default ConfigsProvider;
