import config from 'config';
import Head from 'next/head';

import useNavigation from '@/hooks/useNavigation';

const removeHtmlTags = (text: string) => {
  return text.replace(/<[^>]*>?/g, '');
};

const BlogMetadata = ({ initialBlogContent }: { initialBlogContent?: any }) => {
  const navigation = useNavigation();

  if (!initialBlogContent) {
    return null;
  }

  const domain = config.host_domain;

  const defaultImage = `${config.cdn_domain}/assets/images/cards/article-card-01.svg`;

  return (
    <Head>
      <title>{initialBlogContent?.name || ''}</title>
      {/* Open Graph / Facebook */}
      {/* <meta content="article" property="og:type" /> */}
      <meta content={initialBlogContent?.name || ''} property="og:title" />
      <meta content={initialBlogContent?.author?.name || ''} name="author" property="og:author" />
      <meta
        content={initialBlogContent?.createdAt || ''}
        name="publish_date"
        property="og:publish_date"
      />

      <meta
        content={removeHtmlTags(initialBlogContent?.description || '')}
        property="og:description"
      />
      <meta content={initialBlogContent?.cover?.url || defaultImage} property="og:image" />
      <meta content={`${domain}${navigation.asPath}`} property="og:url" />

      {/* Additional tags for Slack */}
      {/* <meta content={initialBlogContent?.cover?.url || ''} name="image" />
      <meta content={initialBlogContent?.name || ''} name="title" />
      <meta content="500" property="og:image:width" />
      <meta content="500" property="og:image:height" />
      <meta content={initialBlogContent?.name || ''} property="og:image:alt" /> */}

      {/* Twitter */}
      <meta content="summary_large_image" name="twitter:card" />
      <meta content={initialBlogContent?.name || ''} name="twitter:title" />
      <meta content={initialBlogContent?.description || ''} name="twitter:description" />
      <meta content={initialBlogContent?.cover?.url || defaultImage} name="twitter:image" />
      <meta content={initialBlogContent?.author?.name || ''} name="twitter:creator" />
    </Head>
  );
};

export default BlogMetadata;
