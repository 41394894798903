var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"0cf1f885b4556fcf476eec72e22af4bb3bbeebac"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/v2";

/* eslint-disable no-process-env */
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { jsonParse } from '@lyearn/core/utils/json';
import * as Sentry from '@sentry/nextjs';
import config from 'config';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

// Branch build on vercel: preview
// Master build on aws: master
// Production build on aws: production
const environment = process.env.NEXT_PUBLIC_VERCEL_ENV ? 'preview' : process.env.NEXT_PUBLIC_ENV;

const TracesSampleRate = {
  production: 0.1,
  master: 0.05,
  preview: 0,
};

Sentry.init({
  release: config.app_version,
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: TracesSampleRate[environment] || 0,
  environment,
  beforeBreadcrumb(breadcrumb, hint) {
    try {
      if (breadcrumb.category === 'fetch') {
        // input is fetch arguments 1st params is url and 2nd is fetchOptions
        const url = hint?.input?.[0];
        const fetchOptions = hint?.input?.[1];
        const extraBreadcrumbs = {
          requestId: fetchOptions?.headers?.['x-request-id'],
          onLine: navigator.onLine,
        };

        if (navigator.connection) {
          extraBreadcrumbs.effectiveType = navigator.connection.effectiveType;
          extraBreadcrumbs.rtt = navigator.connection.rtt;
        }

        if (fetchOptions?.body) {
          const { data } = jsonParse(fetchOptions?.body);
          // log extra info for graphql
          if (data?.operationName) {
            extraBreadcrumbs.operationName = data?.operationName;
            extraBreadcrumbs.variables = data?.variables;
          }
        }

        // logs info on vercel
        if (environment !== 'production') {
          console.debug('[NETWORK]', url, extraBreadcrumbs);
        }

        // added to data to display in sentry ui. else it will be only in json
        if (breadcrumb.data) {
          breadcrumb.data = { ...breadcrumb.data, ...extraBreadcrumbs };
        }

        return { ...breadcrumb, ...extraBreadcrumbs };
      }
    } catch (e) {
      return breadcrumb;
    }
    return breadcrumb;
  },
  beforeSend(event, hint) {
    const error = hint.originalException;

    // Ignore plyr caption error, keyboard `c` trigger toggleCaptions even when captions are not available
    // Ref:https://lyearn.sentry.io/issues/4325317722/
    if (
      error?.message?.indexOf("Cannot read properties of undefined (reading 'language')") !== -1 &&
      error?.stack?.indexOf('toggleCaptions') !== -1
    ) {
      return null;
    }
    return event;
  },
  ignoreErrors: [
    'Could not send message through transport, error', // sentry replay error
    /^AbortError/, // Ignore "expected" error from aborted fetch
    'Cannot redefine property: googletag', // Google Tag manager error
    '_avast_submit', // Avast extension error
    'Java exception was raised', // Android internal issues
    'Blocked a frame with origin', //generated by a bug in autofill library from safari https://github.com/getsentry/sentry/issues/5267
    // Ignore plyr network error ref https://github.com/sampotts/plyr/issues/1623
    // same as `Non-Error exception captured which changed in senty https://github.com/getsentry/sentry-javascript/pull/8374`
    'Event `CustomEvent` (type=error) captured as exception',
    'ResizeObserver loop limit exceeded',
    // called from pdf.js when the component is unmounted before rendering, it does not affect the user.
    // it is fixed in latest version on react-pdf but latest version is breaking for large pdfs of somos users(rendering black screen)
    'Worker was destroyed',
    'ResizeObserver loop completed with undelivered notifications.',
    // this should not affect client functionality
    // https://github.com/jitsi/lib-jitsi-meet/issues/1873#issuecomment-1030668733
    'No SSRC lines found in remote SDP for remote stream',
    'User canceled screen sharing prompt', // thrown by jitsi on not allowing permission
    'Strophe: Websocket error', // thrown by jitsi
    // https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447/2
    /^((?!urql:).*)((Failed to fetch)|(NetworkError when attempting to fetch resource)|(cancelled)|(Load failed)|(cancelado))/,
    /Loading chunk \d+ failed.\s\((timeout): .+\)/,
    /NotAllowedError: Permission (dismissed|denied)/, // user explicitly denied permission for mic or camera, it is handled in ui
    'msDiscoverChatAvailable',
    'The user denied permission to use Service Worker',
    'InvalidStateError: Failed to get ServiceWorkerRegistration objects.', // in iframe
  ],
  // Replay
  // Capture 10% of all sessions
  replaysSessionSampleRate: environment === 'production' ? 0.1 : 0,
  // Of the remaining 90% of sessions, if an error happens start capturing rate 20%
  replaysOnErrorSampleRate: environment === 'production' ? 0.2 : 0,
  integrations: [],
});
