// after a mutation is completed we may want to refetch some query or fragment in that case we will invalidate the
// corresponding query, so next time that component is rendered it will be fetched from new data from server.
// https://formidable.com/open-source/urql/docs/graphcache/custom-updates/#cacheinvalidate

import { UpdatesConfig } from '@urql/exchange-graphcache';

import { onPostCreate } from '@/features/messages/graphql/subscriptions/updaters/ChannelPostsUpdater';
import { saveWorkspaceAgendaItemsUpdater } from '@/modules/1on1s/views/1on1AgendaSettings/graphql/updater/saveWorkspaceAgendaItemsUpdater';
import { getMetaProgressUpdater } from '@/modules/corporate/modules/course/graphql/mutation/updater/syncProgress';
import { AddExerciseSubmissionFeedbackMutation } from '@/modules/corporate/modules/exercise/views/Review/graphql/mutations/generated/addExerciseSubmissionFeedback';
import addRemoveQuizQuestionsUpdater from '@/modules/corporate/modules/quiz/views/QuizEditor/graphql/updaters/addRemoveQuizQuestions';
import createQuestionsUpdater from '@/modules/corporate/modules/quiz/views/QuizEditor/graphql/updaters/createQuestions';
import updateQuizQuestionsUpdater from '@/modules/corporate/modules/quiz/views/QuizEditor/graphql/updaters/updateQuizQuestions';
import createTrackUpdater from '@/modules/org-hierarchy/modules/tracks/graphql/updaters/createTrackUpdater';
import updateOrgRoleDetails from '@/modules/org-hierarchy/modules/tracks/views/TrackDetails/graphql/updaters/updateOrgRoleDetails';
import updateTrackDetails from '@/modules/org-hierarchy/modules/tracks/views/TrackDetails/graphql/updaters/updateTrackDetails';
import { PraisesDocument } from '@/modules/praises/views/PraiseWall/graphql/queries/generated/praises';
import { createPraiseMutation } from '@/modules/praises/views/PraiseWall/graphql/updater/createPraiseMutationUpdater';
import { updateActionItemMutation } from '@/modules/tasks/features/actionItems/graphql/subscription/updater/getActionItemUpdater';
import { createFileMutation } from '@/modules/tasks/views/TasksDashboard/graphql/updater/createFileMutationUpdater';
import { updateFileMutation } from '@/modules/tasks/views/TasksDashboard/graphql/updater/updateFileMutationUpdater';
import revokeUserPublicAPICredentialsUpdater from '@/modules/user/views/UserProfile/graphql/updaters/revokeUserPublicAPICredentials';
import manageUserSurveyQuestionsUpdater from '@/modules/user-survey/views/UserSurveyEditor/graphql/updaters/manageUserSurveyQuestions';

import createOrgRoleUpdater from '../../../modules/org-hierarchy/modules/org-roles/graphql/updaters/createOrgRoleUpdater';
import deleteOrgRoleUpdater from '../../../modules/org-hierarchy/modules/org-roles/graphql/updaters/deleteOrgRoleUpdater';
import deleteTrackUpdater from '../../../modules/org-hierarchy/modules/tracks/graphql/updaters/deleteTrackUpdater';
import { deleteMutation, invalidateFragment, invalidateQuery } from './cacheHelpers';

const cacheMutations: UpdatesConfig['Mutation'] = {
  createResource: invalidateQuery('resources'),
  deleteResource: invalidateFragment('deleteResource', 'ResourceType', 'resourceId'),
  updatePerformanceStatus: invalidateQuery(['content', 'contents', 'orgRole']),
  // database
  upsertDatabaseData: invalidateQuery(['databaseData', 'content']),
  updateDatabaseView: invalidateQuery(['databaseData', 'databaseMetadata']),
  removeDatabaseData: invalidateQuery('databaseData'),
  updateDatabaseMetadata: invalidateQuery('databaseView'),
  createDatabaseView: invalidateQuery('databaseMetadata'),
  upsertDatabaseFields: invalidateQuery(['databaseData', 'databaseView']),
  upsertScorecardReview: invalidateQuery(['performanceInteractions', 'contentPerformances']),
  markSubmissionAsFeature: invalidateQuery('contentPerformances'),
  createScorecard: invalidateQuery('scorecards'),
  updateTask: invalidateQuery('contents'),
  updateExternalContent: invalidateQuery('contents'),
  upsertCourse: invalidateQuery(['contents', 'content']),
  makeExerciseSubmission: invalidateQuery('contentPerformances'),
  resubmitExerciseSubmission: invalidateQuery('contentPerformances'),
  evaluateExerciseSubmission: invalidateQuery([
    'contentPerformances',
    'performanceInteractions',
    'submissionToReview',
  ]),
  // quiz
  retakeQuiz: invalidateQuery(['content']),
  submitQuiz: invalidateQuery(['contentPerformances']),
  //course
  updateExercise: invalidateQuery('contents'),
  updateScorm: invalidateQuery('content'),
  updateScorecard: invalidateQuery('scorecard'),
  updatePath: invalidateQuery(['contents', 'content']),
  setEntityAccess: invalidateQuery('content'),
  duplicateContent: invalidateQuery('contents'),
  removeEntityAccess: invalidateQuery('content'),
  syncProgress: getMetaProgressUpdater(),
  //skills
  submitReviewCycleResponse: invalidateQuery(['reviewCycleMyReviewees', 'performanceInteractions']),
  // certificate
  updateCertificateTemplate: invalidateQuery('certificateTemplates'),
  // events
  createEvent: invalidateQuery('events'),
  updateEvent: invalidateQuery(['events', 'event']),
  duplicateEvent: invalidateQuery('events'),
  unregisterEvent: invalidateQuery(['event', 'events']),
  registerEvent: invalidateQuery(['event', 'events']),
  updateEventStatus: invalidateQuery(['events']),
  publishAssignment: invalidateQuery(['eventAttendeesV2', 'event', 'events']),
  modifyUsersInEvent: invalidateQuery(['eventAttendeesV2', 'event', 'events']),
  deleteEventPerformance: invalidateQuery(['eventAttendeesV2']),
  // markEventAttendance: invalidateQuery(['event']), //
  bulkMarkEventAttendance: invalidateQuery(['eventAttendeesV2']),
  resetContentProgress: invalidateQuery(['contentPerformances', 'eventAttendeesV2']),
  changeDueDate: invalidateQuery(['contentPerformances']),
  forceCompleteContentProgress: invalidateQuery(['contentPerformances', 'eventAttendeesV2']),
  // admin
  inviteUser: invalidateQuery(['users', 'staffRoles', 'externalUserPerformanceInfos']),
  inviteExternalUsersForEntities: invalidateQuery(['externalUserPerformanceInfos']),
  updateContentStatus: invalidateQuery('contents'),
  upsertPage: invalidateQuery(['page__next', 'pagePreview']),
  updateUserInvite: invalidateQuery('users'),
  // external-content
  updateExternalContentPerformance: invalidateQuery('content'),
  // tags
  createTag: invalidateQuery('tags'),
  updateTag: invalidateQuery(['tags', 'tag']),
  archiveTag: invalidateQuery(['tags', 'tag']),
  removeContentTag: invalidateQuery(['contents', 'tag']),
  removeUserTag: invalidateQuery(['users', 'tag']),
  removeEventTag: invalidateQuery(['events', 'tag']),
  removeOKRTag: invalidateQuery(['okrs', 'tag']),
  // search
  addUserSearchActivity: invalidateQuery('userSearchActivities'),
  deleteUserSearchActivity: invalidateQuery('userSearchActivities'),
  // bookmarks
  createBookmark: invalidateQuery('paginatedBookmarks'),
  deleteBookmark: invalidateQuery('paginatedBookmarks'),
  // group
  createUserCollectionGroup: invalidateQuery('groups'),
  createGroup: invalidateQuery('groups'),
  updateUserCollectionGroup: invalidateQuery(['groups', 'group', 'users']),
  updateGroup: invalidateQuery(['groups', 'group', 'contents']),
  archiveGroup: invalidateQuery(['groups', 'group']),
  duplicateGroup: invalidateQuery(['groups']),
  addUsersToGroup: invalidateQuery(['users', 'groups', 'group']),
  addContentsToGroup: invalidateQuery(['contents', 'groups', 'group']),
  removeUsersFromGroup: invalidateQuery(['users', 'groups', 'group']),
  removeContentsFromGroup: invalidateQuery(['contents', 'groups', 'group']),
  updateUser: invalidateQuery(['user', 'users', 'orgRoles']), // discuss with @nil1511 before changing
  updateUsers: invalidateQuery(['users', 'orgRoles']),
  // community
  upsertCommunity: invalidateQuery(['community', 'channels']),
  // adding message to cache from mutation, instead of adding it to cache from subscription
  // when we are adding message from the subscription, the messages are getting replaced instead of being added :(
  // need to debug why adding via subscription is not working

  //todo = uncomment below line, revert these changes
  // createPost: (result, _args, cache, info) => {
  //   invalidateQuery(['peerFeedback'])(result, _args, cache, info);

  //   const post = (result as CreatePostMutation).createPost?.post;

  //   if (post) {
  //     onPostCreate(cache, post);
  //   }
  // },
  createPost: invalidateQuery(['posts']),
  deletePost: invalidateQuery(['peerFeedback']),
  //survey
  updateSurveyPerformance: invalidateQuery('content'),
  // staff roles
  deleteStaffRole: invalidateQuery(['staffRoles']),
  createStaffRole: invalidateQuery(['staffRoles']),
  updateStaffRole: invalidateQuery(['role', 'user']),
  addUsersToStaffRoles: invalidateQuery(['role', 'users', 'user']),
  removeUsersFromStaffRoles: invalidateQuery(['role', 'users', 'user']),
  removeUsersFromStaff: invalidateQuery(['users', 'staffRoles', 'user']),
  updateUsersStaffRoles: invalidateQuery(['users', 'staffRoles', 'user']),
  // Lyearn connect
  requestConnection: invalidateQuery([
    'sharedConnections',
    'sharedConnection',
    'contents',
    'events',
    'groups',
  ]),
  reviewConnection: invalidateQuery([
    'sharedConnections',
    'sharedConnection',
    'contents',
    'events',
    'groups',
  ]),
  // Lyearn connect details
  shareItems: invalidateQuery([
    'contents',
    'content',
    'parentContents',
    'events',
    'sharedConnection',
    'sharedConnections',
    'groups',
  ]),
  stopItemsSharing: invalidateQuery([
    'contents',
    'content',
    'parentContents',
    'events',
    'sharedConnection',
    'sharedConnections',
    'groups',
  ]),
  updateSharedItemsDuplicationStatus: invalidateQuery(['contents', 'content']),
  eventSharingSelfEnrollCourse: invalidateQuery(['content']),
  // report
  createReport: invalidateQuery('reports'),
  updateReport: invalidateQuery(['reports']),
  deleteReport: invalidateQuery('reports'),
  // org-role
  createOrgRole: createOrgRoleUpdater,
  deleteOrgRole: deleteOrgRoleUpdater,
  updateOrgRole: updateOrgRoleDetails,
  // department
  deleteDepartment: invalidateQuery('departments'),
  createDepartment: invalidateQuery('departments'),
  // track
  deleteTracks: deleteTrackUpdater,
  createTrack: createTrackUpdater,
  updateTrack: updateTrackDetails,
  // versioning
  publishArticle: invalidateQuery('contentVersions'),
  publishCourse: invalidateQuery('contentVersions'),
  discardUnpublishedChanges: invalidateQuery(['content', 'contents']),
  addExerciseSubmissionFeedback: (result, _args, cache, info) => {
    const post = (result as AddExerciseSubmissionFeedbackMutation).addExerciseSubmissionFeedback
      ?.post;

    if (post) {
      onPostCreate(cache, post);
    }
    invalidateQuery(['contentPerformances'])(result, _args, cache, info);
  },

  //email digest
  createEmailDigest: invalidateQuery('emailDigests'),
  deleteEmailDigest: invalidateQuery('emailDigests'),
  // okrs
  deleteOKRCycle: invalidateQuery('okrCycles'),
  updateOKRCycle: invalidateQuery('okrCycles'),
  // external-content-catalogue
  importContentFromMarketplace: invalidateQuery(['marketplaceContents', 'page__next', 'widget']),
  removeMarketplaceImportedContent: invalidateQuery([
    'marketplaceContents',
    'page__next',
    'widget',
  ]),
  // skills
  createSkillMatrix: invalidateQuery('skillMatrices'),
  importSkillMatrixTemplate: invalidateQuery('skillMatrices'),
  duplicateSkillMatrix: invalidateQuery('skillMatrices'),
  deleteSkillMatrix: invalidateQuery('skillMatrices'),
  updateSkillMatrix: invalidateQuery(['skillMatrices', 'skillMatrix']),
  createSkill: invalidateQuery('skills'),
  deleteSkill: invalidateQuery('skills'),
  importSkilTemplates: invalidateQuery('skills'),
  checkinObjective: invalidateQuery('auditMetadatas'),
  checkinKeyResult: invalidateQuery('auditMetadatas'),

  // skill review
  deleteReviewCycle: invalidateQuery('reviewCycles'),
  createReviewCycle: invalidateQuery('reviewCycles'),
  updateReviewCycle: invalidateQuery(['reviewCycles', 'reviewCycle']),
  shareReview: invalidateQuery(['performanceInteractions', 'reviewShareStatusDistribution']),
  updateUserNomination: invalidateQuery([
    'userNominations',
    'userNominationCountDistributionByStatus',
  ]),
  updateReviewCycleStatus: invalidateQuery([
    'userNominationCountDistributionByStatus',
    'userNominations',
  ]),
  // 1on1s
  createMeeting: invalidateQuery('meetings'),
  deleteMeeting: invalidateQuery('meetings'),
  updateMeetingInstanceStatus: invalidateQuery('meetingInstances'),
  createMeetingInstance: invalidateQuery('meetingInstances'),
  deleteMeetingInstances: invalidateQuery('meetingInstances'),
  moveMeetingInstanceAgendaItems: invalidateQuery(['meetingInstance', 'actionItems']),
  undoMoveMeetingInstanceAgendaItems: invalidateQuery([
    'meetingInstance',
    'meetingInstances',
    'actionItems',
  ]),
  updateMeeting: invalidateQuery([
    'meetingInstances',
    'meetingInstance',
    'paginatedGroupedActionItems',
    'agendaItemTemplates',
  ]),
  createAgendaItem: invalidateQuery(['meetingInstance', 'agendaItems', 'agendaItemTemplates']),
  deleteAgendaItem: invalidateQuery(['meetingInstance', 'agendaItems']),
  duplicateAgendaItem: invalidateQuery(['meetingInstance', 'agendaItems', 'agendaItemTemplates']),
  updateMeetingInstanceAgendaItemsOrder: invalidateQuery(['meetingInstance', 'agendaItems']),
  //agenda template
  createAgendaItemTemplate: invalidateQuery('agendaItemTemplates'),
  deleteAgendaItemTemplate: invalidateQuery(['agendaItemTemplates', 'meeting']),
  duplicateAgendaItemTemplate: invalidateQuery(['agendaItemTemplates']),
  updateAgendaItemTemplate: invalidateQuery(['agendaItemTemplates']),
  // systemLevelAgendas
  saveWorkspaceAgendaItems: saveWorkspaceAgendaItemsUpdater(),
  //actionItems
  createActionItem: invalidateQuery([
    'actionItems',
    'paginatedGroupedActionItems',
    'files',
    'peerFeedback',
  ]),
  deleteActionItem: invalidateQuery([
    'actionItems',
    'paginatedGroupedActionItems',
    'files',
    'peerFeedback',
  ]),
  // activity-metrics
  attachActivityMetrics: invalidateQuery(['activityMetrics', 'orgRole']),
  // metrics
  createMetric: invalidateQuery(['metrics']),
  updateMetric: invalidateQuery(['metrics']),
  deleteMetric: invalidateQuery(['metrics']),
  upsertUserActivityMetrics: invalidateQuery(['metrics', 'userActivityMetrics']),
  deleteUserActivityMetrics: invalidateQuery(['metrics', 'userActivityMetrics']),
  // tasks
  createActionItems: invalidateQuery([
    'actionItems',
    'paginatedGroupedActionItems',
    'peerFeedback',
  ]),
  /**
   * after auto sync from jira we need to refetch okr and file (to reflect task real time)
   * Removing this may lead to bugs (things will only work when refreshed)
   */
  modifyTaskCreationConfigs: invalidateQuery(['file']),
  // feedback
  createPeerFeedback: invalidateQuery('peerFeedbacks'),
  deletePeerFeedback: invalidateQuery('peerFeedbacks'),
  updatePeerFeedback: invalidateQuery('peerFeedbacks'),
  //praises
  createPraise: createPraiseMutation(),
  deletePraise: deleteMutation('praises', 'deletePraise', 'praiseId', PraisesDocument),
  // task
  createFile: createFileMutation(),
  deleteFile: invalidateQuery('files'),
  updateFile: updateFileMutation(),
  mergeFile: invalidateQuery('files'),
  moveFile: invalidateQuery('files'),
  updateActionItem: updateActionItemMutation(),
  upsertUserView: invalidateQuery(['file', 'meeting', 'okr']),
  upsertStatuses: invalidateQuery('statuses'),
  updateEntitiesTasksRelation: invalidateQuery([
    'actionItems',
    'paginatedGroupedActionItems',
    'actionItem',
  ]),
  revokeUserPublicAPICredentials: revokeUserPublicAPICredentialsUpdater,

  // questions
  createQuestions: createQuestionsUpdater,
  removeQuestions: invalidateQuery(['questions']),
  addRemoveQuizQuestions: addRemoveQuizQuestionsUpdater,
  updateQuizQuestions: updateQuizQuestionsUpdater,

  manageUserSurveyQuestions: manageUserSurveyQuestionsUpdater,
  publishUserSurvey: invalidateQuery(['userSurveys']),
  archiveUserSurvey: invalidateQuery(['userSurveys']),
  closeUserSurvey: invalidateQuery(['userSurveys']),
  upsertFlagSurveyResponse: invalidateQuery(['flagSurveyResponses']),

  // themes
  createTheme: invalidateQuery(['themes']),

  // shared views
  deleteSharedView: invalidateQuery(['sharedViews']),
  updateSharedView: invalidateQuery(['sharedViews', 'sharedView']),
  createSharedView: invalidateQuery(['sharedViews']),

  // action plan
  deleteActionPlan: invalidateQuery(['actionPlans']),
  duplicateActionPlan: invalidateQuery(['actionPlans']),

  //skill details page
  assignContent: invalidateQuery(['getRecommendedContentsForSkillIds']),

  //journeys
  createJourney: invalidateQuery(['journeys']),
  updateJourney: invalidateQuery(['journeys', 'journey']),
  archiveJourney: invalidateQuery(['journeys']),
  deleteJourney: invalidateQuery(['journeys']),
  createJourneyStep: invalidateQuery(['journeySteps']),
  updateJourneyStep: invalidateQuery(['journeySteps', 'content']),
  deleteJourneyStep: invalidateQuery(['journeySteps']),
  submitJourneyStepForm: invalidateQuery(['journeySteps', 'journeys', 'journeyPerformances']),
  updateJourneyStepTraineeSession: invalidateQuery(['getJourneyStepTraineeSession']),
  publishJourney: invalidateQuery(['journey']),
  // announcements
  createAnnouncement: invalidateQuery(['announcements']),
  updateAnnouncement: invalidateQuery(['announcements', 'announcement']),
  // feedback
  upsertEntityFeedbackSurvey: invalidateQuery(['content']),

  //navbar editor
  updateConfigs: invalidateQuery(['allConfigs', 'configs', 'config', 'navConfig']),

  // knowledge base
  createChannel: invalidateQuery(['channels']),
  removeChannels: invalidateQuery(['channels']),
  createNote: invalidateQuery(['notes']),
  deleteNote: invalidateQuery(['notes']),
  updateChannel: invalidateQuery(['channels']),

  publishToWeb: invalidateQuery(['content']),

  // knowledge-assets
  // invalidating "approveContent" to get rid of the "approve" button on the details page
  approveContent: invalidateQuery(['content']),
  publishContent: invalidateQuery(['content']),
};

export default cacheMutations;
