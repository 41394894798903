import { nanoid } from 'nanoid';
import isURL from 'validator/lib/isURL';

import config from '../../config';
import currentUser from './currentUser';
import fetchTokens from './fetchTokens';

const EMPTY_OBJ: Record<string, string> = Object.freeze({});

const UNAUTHORIZED_STATUS_TEXT = 'Unauthorized';
const isAuthErrorCheck = (response: Response) => {
  return response.status === 401 && response.statusText === UNAUTHORIZED_STATUS_TEXT;
};

export async function request(
  path: string,
  fetchOptions: RequestInit = {},
  hasRefreshed?: boolean,
): Promise<Response> {
  const headers: any = fetchOptions.headers || {};
  if (!headers['x-request-id']) {
    headers['x-request-id'] = nanoid();
  }
  const currAccessToken = headers.accessToken;
  if (currentUser._aT) {
    headers.accessToken = currentUser._aT() || currAccessToken;
  }

  const requestPath = isURL(path, { require_tld: config.is_prod })
    ? path
    : `${config.origin}${path}`;

  // Take last string after '/' and before any '?' or '#' as the operation name
  headers['x-operation-name'] = new URL(requestPath).pathname.split('/').pop();

  const response = await fetch(requestPath, { ...fetchOptions, headers });

  if (isAuthErrorCheck(response) && !hasRefreshed) {
    await fetchTokens();
    return request(path, fetchOptions, true);
  }

  return response;
}

export async function get(path: string, params?: Record<string, string>) {
  const requestUrl = new URL(path, config.origin);
  Object.entries(params ?? EMPTY_OBJ).forEach((item) => requestUrl.searchParams.append(...item));

  return await request(requestUrl.toString(), { method: 'GET' }).then((response) =>
    response.json(),
  );
}

export async function post(path: string, body?: Object, options?: any) {
  const { xRequestId, ...restOptions } = options ?? {};
  return await request(path, {
    method: 'POST',
    headers: { 'content-type': 'application/json', 'x-request-id': xRequestId },
    body: JSON.stringify(body ?? EMPTY_OBJ),
    ...restOptions,
  }).then((response) => {
    try {
      return response.json();
    } catch (e) {
      return response.text();
    }
  });
}
